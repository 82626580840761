import {KycItem} from './kyc-item'
import {
  IBorgoApplication,
  IKycEmploymentDataset,
  IKycPersonDataset,
  TBorgoEmploymentType,
  TKycEmploymentType
} from 'sparbanken-syd-borgo'
import {Employment} from '../../../model/employment/employment'
import {DateTime} from 'luxon'

export const convertToKycEmploymentType = (applicationType: TBorgoEmploymentType): TKycEmploymentType => {
  const map = new Map<TBorgoEmploymentType, TKycEmploymentType>([
    ['PERMANENT_EMPLOYED', 'PERMANENT'],
    ['TEMPORARY_EMPLOYED', 'TEMPORARILY'],
    ['SELF_EMPLOYED', 'PERMANENT'],
    ['PENSIONER', 'OFF_DUTY'],
    ['JOB_SEEKER', 'OFF_DUTY'],
    ['STUDENT', 'OFF_DUTY']
  ])
  return map.get(applicationType)!
}

export const convertFromKycEmploymentType = (kyc: TKycEmploymentType): TBorgoEmploymentType => {
  const map = new Map<TKycEmploymentType, TBorgoEmploymentType>([
    ['PERMANENT', 'PERMANENT_EMPLOYED'],
    ['TEMPORARILY', 'TEMPORARY_EMPLOYED'],
    ['OFF_DUTY', 'PENSIONER'],
    ['CARE_LEAVE', 'PENSIONER'],
    ['PART_TIME', 'TEMPORARY_EMPLOYED'],
    ['FIXED_TERM', 'TEMPORARY_EMPLOYED']
  ])
  return map.get(kyc)!
}

export class KycItemEmployment extends KycItem<IKycEmploymentDataset> {
  /**
   * This object will be set as source of truth upon creation
   *
   * @private
   */
  private readonly pKycEmployment: IKycEmploymentDataset

  private pApplicationEmployment: Employment

  private readonly pApplication: IBorgoApplication

  constructor(input: Partial<IKycPersonDataset> = {}, application: IBorgoApplication) {
    super(input)
    // Sets the original values
    this.pApplication = application
    // We set our original so that it will be kept safe.
    this.pKycEmployment = Object.assign({}, this.getEmployment())

    this.pApplicationEmployment = new Employment(application)

    // IF we _do not_ have an application, we set the application
    // from the Kyc.
    if (this.pApplicationEmployment.employmentType === null) {
      this.setEmploymentFromKyc(this.kycEmployment, this.pApplicationEmployment)
    } else {
      // We actually consider the data from the application to
      // be the source of truth if we get one.
      KycItemEmployment.convertEmploymentToKyc(this.pKycEmployment, this.pApplicationEmployment)
      Object.assign(this.getEmployment(), this.pKycEmployment)

    }

    this.isValid()
  }

  get kycEmployment(): IKycEmploymentDataset {
    /**
     * Heads up, we send the source object...
     * It will get manipulated directly
     */
    return this.getEmployment()
  }

  get applicationEmployment(): Employment {
    return this.pApplicationEmployment
  }

  public static convertEmploymentToKyc(target: IKycEmploymentDataset, source: Employment): void {
    target.formOfEmployment = convertToKycEmploymentType(source.employmentType!)
    target.employmentStartYear = null
    target.employmentStartMonth = null
    target.employmentEndYear = null
    target.employmentEndMonth = null
    target.employer = null
    // The only important fix
    target.occupation = null

    if (source.shouldHaveEmployerName()) {
      target.employer = source.employerName
      target.employmentStartYear = source.employedStartDate?.year || null
      target.employmentStartMonth = source.employedStartDate?.month || null

      if (source.employmentType === 'TEMPORARY_EMPLOYED') {
        target.employmentEndYear = source.temporaryEmployedEndDate?.year || null
        target.employmentEndMonth = source.temporaryEmployedEndDate?.month || null
      }
    }
  }

  public setValue(input: IKycEmploymentDataset): void {
    /**
     * Not really needed since the objects are the same.
     */
    Object.assign(this.getEmployment(), input)
    this.changes$.set(this.differs())
    this.isValid()
  }

  public override reset() {
    this.setValue(this.pKycEmployment)
    this.pApplicationEmployment = new Employment(this.pApplication)
    this.value$.next(this.getEmployment())
  }

  private isValid(): void {
    this.isValid$.next(true)
  }

  private differs(): boolean {
    const current = this.getEmployment()
    return this.compare(this.pKycEmployment, current) ||
      this.compare(current, this.pKycEmployment)
  }

  private getEmployment(): IKycEmploymentDataset {
    if (!this.input.personRegulatoryDataset) {
      this.input.personRegulatoryDataset = {}
    }

    if (!this.input.personRegulatoryDataset.personEmploymentDataset) {
      this.input.personRegulatoryDataset.personEmploymentDataset = {
        employer: null,
        employmentEndMonth: null,
        formOfEmployment: null,
        employmentEndYear: null,
        employmentStartMonth: null,
        employmentStartYear: null,
        profession: null,
        occupation: null
      }
    }
    return this.input.personRegulatoryDataset.personEmploymentDataset
  }

  private compare(you: Partial<IKycEmploymentDataset>, me: Partial<IKycEmploymentDataset>): boolean {
    return Object.keys(you)
      .map(k => {
        const key = k as keyof IKycEmploymentDataset
        return you[key] !== me[key]
      })
      .reduce((c, a) => c || a)
  }

  private setEmploymentFromKyc(source: IKycEmploymentDataset, target: Employment): void {
    target.employerName = source.employer!
    target.employmentType = convertFromKycEmploymentType(source.formOfEmployment!)
    if (source.employmentStartYear && source.employmentStartMonth) {
      target.employedStartDate =
        DateTime.fromObject({year: source.employmentStartYear!, month: source.employmentStartMonth!})
    }

    if (source.employmentEndYear && source.employmentEndMonth)
      target.temporaryEmployedEndDate = DateTime
        .fromObject({year: source.employmentEndYear, month: source.employmentEndMonth})
  }
}
