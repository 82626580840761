import {Component, effect} from '@angular/core'
import {KycBaseItemComponent} from '../0-base/kyc-base-item.component'
import {filter} from 'rxjs/operators'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {KycItemCitizen} from '../../model/kyc-item-citizen'
import {CountryMap} from '../../model/countries'
import {sortOriginal} from '../../../../../application/helpers'

@Component({
  selector: 'spb-kyc-citizen',
  templateUrl: './kyc-citizen.component.html',
  styleUrl: '../kyc-items.scss',
  providers: [{provide: KycBaseItemComponent, useExisting: KycCitizenComponent}]
})
export class KycCitizenComponent extends KycBaseItemComponent {

  public override form = new FormGroup(
    {
      primary: new FormControl<string | null>(null, {nonNullable: true, validators: [Validators.required]}),
      secondary: new FormControl<string | null>(null, {nonNullable: true})
    }
  )

  public hasDouble = new FormControl<boolean>(false)

  public citizen: KycItemCitizen = new KycItemCitizen()

  protected readonly CountryMap = CountryMap
  protected readonly sortOriginal = sortOriginal

  constructor() {
    super()
    effect(() => {
      const input = this.input()
      this.citizen = input.citizen
      this.updateForm(this.citizen.citizen)

      this.citizen.isValid$.subscribe(this.updateKyc)
      this.hasDouble.setValue(this.citizen.citizen.length === 2)

      this.citizen.value$.subscribe({
        next: v =>
          this.updateForm(v)
      })
    })

    this.form.valueChanges
      .pipe(filter(Boolean))
      .subscribe({
        next: (v) => {
          this.citizen.setValue([
            v.primary,
            v.secondary
          ] as any)
        }
      })

    this.hasDouble.valueChanges
      .subscribe({
        next: (v) => {
          if (v) {
            this.form.controls.secondary.setValidators([Validators.required])
          } else {
            this.form.controls.secondary.clearValidators()
            this.form.controls.secondary.setValue(null)
          }
          // Timeout needed since formValid is a signal and will get updated
          // on _next_ iteration??? Fuck I hate this!
          setTimeout(() => {
            this.form.updateValueAndValidity()
          }, 1)
        }
      })
  }

  private updateForm(values: string[]): void {
    this.form.controls.primary.setValue(values[0])
    if (values.length === 2) {
      this.form.controls.secondary.setValue(values[1])
      this.hasDouble.setValue(true)
    } else {
      this.form.controls.secondary.setValue(null)
    }
  }
}
